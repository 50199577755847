<template>
  <v-container class="container-content-base">
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-form @submit.prevent="submit">
        <v-layout column>
          <v-layout column>
            <label class="mr-3 label-dense" style="font-weight: bold">
              {{ $t("name_prices") }}
              <span class="red--text">{{ $t("required") }}</span>
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="textRequired"
              vid="name"
              :rules="requiredRules"
              class="w-fluid"
            >
              <v-text-field
                ref="namePricePartner"
                v-model="name"
                outlined
                dense
                :maxlength="maximumSmallText"
                :placeholder="$t('place_holders.name_prices')"
                :error-messages="errors"
                :messages="errorName"
              />
            </validation-provider>
          </v-layout>
          <v-layout class="d-flex mt-3">
            <v-layout class="" column style="flex: 50">
              <label class="mr-3 label-dense" style="font-weight: bold">
                {{ $t("ship_service") }}
                <span class="red--text">{{ $t("required") }}</span>
              </label>
              <validation-provider
                v-slot="{ errors }"
                name="textRequired"
                :rules="requiredRules"
              >
                <v-select
                  v-model="serviceSelect"
                  outlined
                  dense
                  class="rounded-lg"
                  item-text="serviceDescription"
                  item-value="serviceId"
                  :placeholder="$t('place_holders.ship_service')"
                  :error-messages="errors"
                  :items="lstService"
                  @change="getSales"
                />
              </validation-provider>
            </v-layout>
            <div style="flex: 50" class="d-flex justify-space-between ml-2">
              <div class="d-flex flex-column mr-2" style="flex: 1">
                <label class="mr-3 label-dense" style="font-weight: bold">
                  {{ $t("account_link") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="textRequired"
                    vid="link-select"
                    :rules="requiredRules"
                  >
                    <v-select
                      v-model="accountSelect"
                      outlined
                      dense
                      :disabled="!serviceSelect"
                      class="rounded-lg"
                      item-value="id"
                      item-disabled="isDisable"
                      :items="lstAccount"
                      :placeholder="$t('place_holders.choice_account_link')"
                      :error-messages="errors"
                      :messages="errorLink"
                      :required="errorLink"
                      @change="showDataAccount"
                    >
                      <template v-slot:selection="{ item }">
                        {{ getTextTruncate(item.username, 20) }}
                      </template>
                      <template v-slot:item="{ item }">
                        <v-layout column>
                          <span :style="item.isDisable ? 'color: gray' : ''">
                            {{ item.username }}
                          </span>
                          <span
                            class="mb-2"
                            v-html="getText(item.priceGroupName)"
                          />
                        </v-layout>
                      </template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
              <div
                v-if="showMethodShipping"
                class="d-flex flex-column"
                style="flex: 1"
              >
                <label class="mr-3 label-dense" style="font-weight: bold">
                  {{ $t("shipping_method") }}
                </label>
                <v-text-field v-model="dataMethod" outlined readonly />
              </div>
            </div>
          </v-layout>
          <v-layout class="border-primary rounded px-2 py-3">
            <v-layout column>
              <table class="table-price-list">
                <HeaderPricePartner />
                <tbody>
                  <InputPricePartner
                    v-for="(item, index) in lstPrice"
                    :key="item.vId"
                    :item="item"
                    :idx="index"
                    :on-blur-weight="onBlurWeight"
                    :on-delete-item="onDeleteItem"
                  />
                </tbody>
                <tfoot>
                  <v-icon
                    class="ml-4"
                    color="primary"
                    size="25"
                    @click="addItem(false, false)"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </tfoot>
              </table>
            </v-layout>
          </v-layout>
          <v-layout justify-end class="mt-3">
            <v-btn class="mr-3" @click="cancelCreate">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="invalid || invalidWeight || disabledBtn"
            >
              {{ $t("create_price_list") }}
            </v-btn>
          </v-layout>
        </v-layout>
      </v-form>
    </validation-observer>
    <dialog-loading :is-show="showLoading" :message="$t('processing')" />
    <dialog-notification
      v-model="showNoti"
      :icon-src="getIcon"
      :message="message"
    />
    <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
  </v-container>
</template>

<script>
import constants from "@/constants";
import Breadscrumbs from "@/components/base/Breadscrumbs";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import HeaderPricePartner from "@/views/table_price/components/HeaderPricePartner";
import InputPricePartner from "@/views/table_price/components/InputPricePartner";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import { StorageService } from "@/services/storageService";
import { StringUtils } from "@/helpers/stringUtils";
import { PricesService } from "@/services/pricesService";
import { PartnerService } from "@/services/partnerService";
import { BaseService } from "@/services/baseService";
import routePaths from "@/router/routePaths";
import mixinPricePartner from "@/views/table_price/mixins/mixinPricePartner";
export default {
  components: {
    Breadscrumbs,
    HeaderPricePartner,
    InputPricePartner,
    DialogNotification,
    DialogLoading,
    DialogConfirmTokenExpried,
  },
  mixins: [mixinPricePartner],
  data() {
    return {
      partnerId: parseInt(this.$route.query.id),
      partnerName: this.$route.query.name,
      customerType: constants.customerType.shop,
      type: constants.pricesType.standard,
      name: "",
      maximumSmallText: constants.maximumSmallText,
      requiredRules: {
        required: true,
      },
      groupName: null,
      items: [],
      accountSelect: "",
      lstAccount: [],
      dataMethod: "",
      dataService: "",
      showMethodShipping: false,
      showServiceShipping: false,
      valueMethod: "",
      errorName: "",
      errorLink: "",
      valueService: null,
      linkBack: null,
      breadcrumbs: [
        {
          text: this.$t("partner_list"),
          disabled: false,
          isActive: false,
          href: "",
        },
        {
          text: "",
          disabled: false,
          isActive: false,
          href: "",
        },
        {
          text: "",
          disabled: true,
          isActive: true,
        },
      ],
      lstService: [],
      serviceSelect: null,
      disabledBtn: false,
    };
  },
  created() {
    this.groupName = this.$route.query.groupName;
    this.breadcrumbs[0].href = routePaths.PARTNER_LIST;
    this.breadcrumbs[1].href = `${routePaths.PARTNER_PRICES_LIST}?id=${this.partnerId}&name=${this.partnerName}`;
  },
  beforeMount() {
    this.initData();
    this.onChangeGetServiceAll();
  },
  methods: {
    // danh sach tai khoan lien ket
    async getSales() {
      this.showLoading = true;
      const pars = {
        parnerId: this.partnerId,
        serviceId: this.serviceSelect,
      };
      const { status, data } = await PartnerService.getSales(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.lstAccount = data;
        this.items = data;
        this.lstAccount.forEach((it) => {
          if (it.status === 1) {
            this.lstAccount.push(it);
          }
        });
        this.lstAccount.forEach((it) => {
          if (it.priceGroupName) {
            it.isDisable = true;
          } else {
            it.isDisable = false;
          }
        });
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    async onChangeGetServiceAll() {
      try {
        const res = await BaseService.getLogisticPartner();
        if (res && res.data.length) {
          this.lstService = res.data.filter(
            (item) => item.partnerId === this.partnerId
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    getText(item) {
      if (item) {
        return `<span style="color: red; font-size: 12px"> Đã gắn bảng giá </span>`;
      }
    },
    disableItem(item) {
      if (item.priceGroupName) {
        return true;
      }
      return false;
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    showDataAccount() {
      if (this.partnerId === constants.partnerGroup.ninjaVan) {
        this.showMethodShipping = true;
      }
      this.showServiceShipping = true;
      this.items.forEach((val) => {
        if (val.id === this.accountSelect) {
          this.dataMethod = this.getTextTransportType(val.transportType);
          this.dataService = this.getTextService(val.serviceId);
          this.valueService = val.serviceId;
          this.valueMethod = val.transportType;
        }
      });
    },
    getTextTransportType(val) {
      return val === constants.transportType.ship
        ? this.$t("shipper_pickup")
        : this.$t("customer_bring_order");
    },
    getTextService(val) {
      if (val === constants.servicesShipping.normal) {
        return this.$t("normal");
      } else if (val === constants.servicesShipping.express) {
        return this.$t("fast");
      } else if (val === constants.servicesShipping.standard) {
        return this.$t("normal");
      } else {
        return this.$t("over");
      }
    },
    async initData() {
      this.isCreated = false;
      this.breadcrumbs[1].text = this.partnerName
      this.breadcrumbs[2].text = `Tạo bảng giá ${this.partnerName}`;
      this.initItem();
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid && !this.invalidWeight) {
        this.createPrices();
      }
    },
    cancelCreate() {
      this.name = "";
      this.accountSelect = "";
      this.$router.push({
        path: routePaths.PARTNER_PRICES_LIST,
        query: {
          id: this.$route.query.id,
          name: this.partnerName
        },
      });
    },
    async createPrices() {
      this.showLoading = true;
      this.disabledBtn = true;
      const pars = {
        poId: StorageService.getPostOfficeId(),
        partnerId: this.partnerId,
        name: this.getGroupName(),
        partnerSaleId: this.accountSelect,
        serviceId: this.serviceSelect,
        adminPriceDtos: this.getRequestPars(),
      };
      const { status, data } = await PricesService.adminCreate(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok) {
        this.toggleDialogNoti({
          state: true,
          type: constants.typeAlert.success,
          msg: this.$t("create_success_prices"),
        });
        setTimeout(() => {
          this.$router.push({
            path: routePaths.PARTNER_PRICES_LIST,
            query: {
              id: this.$route.query.id,
              name: this.partnerName
            },
          });
        }, constants.timeOut);
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
        setTimeout(() => {
          this.toggleDialogNoti();
        }, constants.timeOut);
        this.$refs.namePricePartner.focus();
        if (data.message === constants.errorMessage.linkExist) {
          this.errorLink = this.$t("account-link-exist");
        } else {
          this.errorName = this.$t("account-name-exist");
        }
        this.disabledBtn = false;
      }
    },
    getGroupName() {
      return this.name.trim();
    },
    getRequestPars() {
      const rqPars = [];
      if (this.lenLstPrice > 0) {
        const arrPrice = this.sortPricesByWeight();
        arrPrice.forEach((i, index) => {
          var fromWeight = 0;
          if (index > 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 1].weight);
          } else if (index === 1) {
            fromWeight = StringUtils.numStrToFloat(arrPrice[index - 1].weight);
          }
          const iOrigin = this.findItemOriginById(i.vId);
          const item = this.mappingRequestItem(i, fromWeight, iOrigin);
          rqPars.push(item);
        });

        if (this.lstDeleteOrigin.length > 0) {
          const arrDel = this.lstDeleteOrigin;
          arrDel.forEach((item) => {
            item.status = constants.status.del;
            item.hasUpdate = true;
            rqPars.push(item);
          });
        }
      }

      return rqPars;
    },
    mappingRequestItem(i, fromWeight, iOrigin) {
      const item = {};
      item["partnerId"] = this.partnerId;
      item["serviceId"] = constants.serviceType.normal;
      item["poId"] = StorageService.getPostOfficeId();
      item["type"] = this.type;
      item["transportType"] = this.valueMethod;
      item["fromWeight"] = fromWeight;
      item["toWeight"] = i.isMax ? null : StringUtils.numStrToFloat(i.weight);
      item["intracityPrice"] = StringUtils.numStrToFloat(i.intracityPrice);
      item["intracityAccumulatePrice"] = StringUtils.numStrToFloat(
        i.intracityAccumulatePrice
      );
      item["intrazonePrice"] = StringUtils.numStrToFloat(i.intrazonePrice);
      item["intrazoneAccumulatePrice"] = StringUtils.numStrToFloat(
        i.intrazoneAccumulatePrice
      );
      item["interzonePrice"] = StringUtils.numStrToFloat(i.interzonePrice);
      item["interzoneAccumulatePrice"] = StringUtils.numStrToFloat(
        i.interzoneAccumulatePrice
      );
      item["status"] = i.status;

      if (
        typeof iOrigin !== constants.undefined &&
        iOrigin !== null &&
        iOrigin.id > 0
      ) {
        item["id"] = iOrigin.id;
        item["pricesGroupId"] = iOrigin.pricesGroupId;
        item["hasUpdate"] = this.checkItemChange(iOrigin, item, i.isMax);
      } else {
        item["hasUpdate"] = false;
      }

      return item;
    },
    isChangeGroupName(groupNameOrigin) {
      const name = this.getGroupName().toLowerCase();
      return name !== groupNameOrigin.toLowerCase();
    },
  },
};
</script>

<style src="@/styles/createPricePartner.scss" lang="scss">
::v-deep(.v-text-field .v-input__control .v-input__slot) {
  min-height: 40px !important;
}
</style>
